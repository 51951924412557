.chat-hint {
  position: fixed;
  bottom: 90px; /* Increased bottom margin */
  right: 30px;
  background-color: #f0f0f0;
  color: #333333;
  padding: 10px 15px;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  animation: fadeIn 0.5s ease-out, pulse 2s infinite;
  cursor: default;
}

.chat-hint::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  border-radius: 22px;
  z-index: -1;
  filter: blur(5px);
  opacity: 0.7;
  animation: glowing 3s linear infinite;
}

.chat-hint p {
  margin: 0;
  z-index: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes glowing {
  0% {
    filter: blur(5px);
    opacity: 0.7;
  }
  50% {
    filter: blur(7px);
    opacity: 0.9;
  }
  100% {
    filter: blur(5px);
    opacity: 0.7;
  }
}
